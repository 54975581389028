import { Fragment, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

import ProgressCircle from "../../components/ProgressCircle";

import { constants } from "../../utils/ranges";

import { ReactComponent as BackArrow } from "../../assets/icons/BackArrow.svg";
import { ReactComponent as Info } from "../../assets/icons/Info.svg";
import { ReactComponent as BodyWater } from "../../assets/icons/BodyWater.svg";
import { ReactComponent as BodyWaterPercentage } from "../../assets/icons/BodyWaterPercentage.svg";
import { ReactComponent as BloodVolume } from "../../assets/icons/BloodVolume.svg";
import { ReactComponent as BodyFat } from "../../assets/icons/BodyFat.svg";
import { ReactComponent as CalorieCarb } from "../../assets/icons/CalorieCarb.svg";
import { ReactComponent as CalorieFat } from "../../assets/icons/CalorieFat.svg";

const PhysioFitness = () => {
  const location = useLocation();

  const selectedRange = constants.BMI.ranges.reduce((p, c) => (location?.state?.bmi > c.point ? c : p), 0);
  const percentage = Math.round(
    (location?.state?.bmi * 100) /
      (constants.BMI.ranges[constants.BMI.ranges.length - 1].point - constants.BMI.ranges[0].point)
  );

  const [modalState, setModalState] = useState({ visible: false, title: "", desc: "" });
  const closeModal = () => setModalState({ visible: false, title: "", desc: "" });

  return (
    <section className="px-6 py-2">
      <div className="flex items-center text-primary">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Physio Fitness</h3>
      </div>

      <div className="my-6 space-y-6">
        <div className="flex flex-col items-center">
          <ProgressCircle
            size={120}
            value={percentage}
            title={location?.state?.bmi?.toString?.()}
            subtitle={constants.BMI.unit}
            color={selectedRange.color}
          />
          <h2 className="mt-3 text-primary text-sm font-medium">Latest BMI Score</h2>
          <h4 className="mt-1 text-xxs font-medium uppercase" style={{ color: selectedRange.color }}>
            {selectedRange.label}
          </h4>
        </div>

        <div className="grid grid-cols-2 gap-4">
          {[
            {
              label: "Total Body Water",
              value: location?.state?.tbw,
              icon: <BodyWater className="flex-shrink-0 h-6 w-6" />,
            },
            {
              label: "Body Water %",
              value: location?.state?.tbwp,
              icon: <BodyWaterPercentage className="flex-shrink-0 h-6 w-6" />,
            },
            {
              label: "Blood Volume",
              value: location?.state?.bloodvolume,
              icon: <BloodVolume className="flex-shrink-0 h-6 w-6" />,
            },
            {
              label: "Total Body Fat",
              value: location?.state?.bodyfat,
              icon: <BodyFat className="flex-shrink-0 h-6 w-6" />,
            },
            {
              label: "Calorie from Carb",
              value: location?.state?.cal_carb,
              icon: <CalorieCarb className="flex-shrink-0 h-6 w-6" />,
            },
            {
              label: "Calorie from Fat",
              value: location?.state?.cal_fat,
              icon: <CalorieFat className="flex-shrink-0 h-6 w-6" />,
            },
          ].map((item, index) => (
            <button
              type="button"
              key={`param-${index}`}
              className="px-3 py-4 rounded-2xl shadow-box flex flex-col items-stretch justify-between text-left"
              onClick={() =>
                setModalState({ visible: true, title: item.label, desc: constants[item.label].description })
              }
            >
              <div className="flex items-start justify-between space-x-1">
                {item.icon}
                <h2 className="flex-grow text-primary text-xs font-medium">{item.label}</h2>
                <Info className="flex-shrink-0 h-4 w-4 text-primary/50" />
              </div>
              <div className="mt-3 break-all font-semibold">
                <h4 className="inline text-secondary text-xl">{item.value}</h4>
                &nbsp;
                <h5 className="inline text-primary/70 text-xxs">{constants[item.label].unit}</h5>
              </div>
            </button>
          ))}
        </div>
        <Transition show={modalState.visible} as={Fragment}>
          <Dialog as={Fragment} onClose={closeModal}>
            <Transition.Child
              className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
              enter="duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="duration-300 delay-150"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Transition.Child
                as={Fragment}
                enter="delay-150 duration-200"
                enterFrom="opacity-0 scale-50"
                enterTo="opacity-100 scale-100"
                leave="duration-100"
                leaveFrom="opacity-50 scale-50"
                leaveTo="opacity-0 scale-0"
              >
                <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                  <Dialog.Title className="text-primary text-xs font-medium">{modalState.title}</Dialog.Title>
                  <Dialog.Description className="mt-2 text-primary/70 text-xxs">{modalState.desc}</Dialog.Description>
                  <button
                    className="mt-4 px-4 py-2 rounded-full bg-secondary text-white text-xs font-medium"
                    type="button"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </Dialog.Panel>
              </Transition.Child>
            </Transition.Child>
          </Dialog>
        </Transition>
      </div>
    </section>
  );
};

export default PhysioFitness;
