import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

import LoadingScreen from "../components/LoadingScreen";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const ProgressBar = ({ progress = 0, color = "#64BD40", style = {} }) => (
  <div className="h-2 bg-lightgray/50 rounded-full overflow-hidden" style={style}>
    <div
      className="h-full rounded-full transition-all duration-500"
      style={{ width: `${Math.min(Math.max(progress * 100, 0), 100)}%`, backgroundColor: color }}
    />
  </div>
);

const Survey = () => {
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [surveyID, setSurveyID] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState({});
  const [errorID, setErrorID] = useState("");

  const questionsPerPage = 3;
  const [pageNo, setPage] = useState(1);
  const pageCount = useMemo(() => Math.ceil(questions.length / questionsPerPage), [questions.length]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      setLoading(true);
      try {
        const token = await getToken();
        const questionResp = await fetch(sdkWrapperURL("/users/v1/get_surveys"), {
          signal: controller.signal,
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({ action: "temporary", table_config_rows_per_page: 100, table_config_page_no: 1 }),
        });
        const questionRespJSON = await questionResp.json();
        if (questionRespJSON?.statusCode?.toString().startsWith("2")) {
          if (questionRespJSON.response?.length > 0) {
            const selectedSurvey = questionRespJSON.response
              .sort((a, b) => a.survey_priority - b.survey_priority)
              .find((s) => s.is_fillable);
            if (selectedSurvey?.qtm_id?.length > 0) {
              const questionsList = selectedSurvey.questions_detail.map((q) => ({
                id: q.question_id,
                question: q.question,
                options: q.question_options,
                // eslint-disable-next-line eqeqeq
                required: q.is_mandatory == 1,
              }));
              setSurveyID(selectedSurvey.qtm_id);
              setQuestions(questionsList);
              setAnswers(Object.fromEntries(questionsList.map((q) => [q.id, ""])));
              setErrorID("");
              setPage(1);
            } else throw new Error("No Fillable Surveys");
          } else navigate("/scan-result", { replace: true, state: { result: location?.state?.result ?? {} } });
        } else throw new Error(questionRespJSON?.message ?? "Error in Fetching Survey");
      } catch (err) {
        console.error(err);
        navigate("/scan-result", { replace: true, state: { result: location?.state?.result ?? {} } });
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSurvey = async () => {
    setSaving(true);
    try {
      setErrorID("");
      const error = questions.find((q) => q.required && !(answers[q.id]?.length > 0))?.id;
      if (error?.length > 0) setErrorID(error);
      else {
        const token = await getToken();
        const resp = await fetch(sdkWrapperURL("/users/v1/question_answers"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({ action: "temporary", qtm_id: surveyID, questions_answers: answers }),
        });
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2"))
          navigate("/scan-result", { replace: true, state: { result: location?.state?.result ?? {} } });
        else throw new Error(respJSON?.message ?? "Could not Save.");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setSaving(false);
    }
  };

  const saveAnswer =
    (questionID = "") =>
    (answer) =>
      setAnswers((ans) => ({ ...ans, [questionID]: answer }));

  const questionsOnPage = useCallback(
    (page) => {
      const starting_index = (page - 1) * questionsPerPage;
      return questions.slice(starting_index, starting_index + questionsPerPage);
    },
    [questions, questionsPerPage]
  );

  const nextPage = () => {
    setErrorID("");
    setPage((p) => {
      const error = questionsOnPage(p).find((q) => q.required && !(answers[q.id]?.length > 0))?.id;
      if (error?.length > 0) {
        setErrorID(error);
        return p;
      } else return p + (p < pageCount);
    });
  };

  const backPage = () => {
    setErrorID("");
    setPage((p) => p - (p > 1));
  };

  return location?.state?.result?.scan_id?.length > 0 ? (
    <section className="px-6 py-2">
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <h2 className="text-secondary text-sm font-medium">Help us to know more about your health</h2>
          <p className="mt-2 text-primary/60 text-xs">We need few more information to do a better health assessment.</p>

          <div className="my-6">
            <h3 className="mb-2.5 text-primary text-sm font-semibold">
              STEP {pageNo} of {pageCount}
            </h3>
            <ProgressBar progress={saving ? 100 : (pageNo - 1) / pageCount} />
            <div className="mt-4 space-y-6">
              {questionsOnPage(pageNo).map((q, qIndex) => (
                <div
                  key={q.id}
                  className="px-6 py-4 flex items-start justify-start space-x-4 rounded-2xl bg-white shadow-box"
                >
                  <span className="flex-shrink-0 h-6 w-6 flex items-center justify-center rounded-full border border-current text-primary/60 text-xs font-semibold">
                    {(pageNo - 1) * questionsPerPage + qIndex + 1}
                  </span>
                  <div>
                    <h6 className="text-primary/75 text-xs font-medium">
                      {q.question}
                      {q.required && <span className="text-error">&nbsp;*</span>}
                    </h6>
                    <div className="mt-2 flex flex-wrap items-start justify-start">
                      {q.options.map((opt, optIndex) => (
                        <div key={`${q.id}-opt-${optIndex}`} className="mr-4 my-1.5 flex items-center space-x-1">
                          <input
                            type="radio"
                            name={q.id}
                            id={`${q.id}-opt-${optIndex}`}
                            required={q.required}
                            checked={opt === answers[q.id]}
                            value={opt}
                            onChange={(e) => saveAnswer(q.id)(e.target.value)}
                          />
                          <label htmlFor={`${q.id}-opt-${optIndex}`} className="text-primary/75 text-xs">
                            {opt}
                          </label>
                        </div>
                      ))}
                    </div>
                    {q.id === errorID && <p className="mt-1 text-error text-xxs">Please answer this question</p>}
                  </div>
                </div>
              ))}
              <div className="flex items-stretch justify-between">
                <button
                  className="w-32 px-4 py-2 border border-secondary rounded-full bg-white text-primary text-xs font-medium"
                  type="button"
                  onClick={pageNo > 1 ? backPage : () => setModalOpen(true)}
                  disabled={saving}
                >
                  {pageNo > 1 ? "Previous" : "Skip"}
                </button>
                <button
                  className="w-32 flex items-center justify-center space-x-2 px-4 py-2 border border-secondary rounded-full bg-secondary disabled:bg-darkgray text-white"
                  type="button"
                  onClick={pageNo < pageCount ? nextPage : saveSurvey}
                  disabled={saving}
                >
                  <span className="text-xs font-medium">{pageNo < pageCount ? "Next" : "Save"}</span>
                  {saving && <Loader className="flex-shrink-0 h-4 w-4" />}
                </button>
              </div>
            </div>
          </div>
          <Transition show={isModalOpen} as={Fragment}>
            <Dialog as={Fragment} onClose={() => setModalOpen(false)}>
              <Transition.Child
                className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
                enter="duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="duration-300 delay-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Transition.Child
                  as={Fragment}
                  enter="delay-150 duration-200"
                  enterFrom="opacity-0 scale-50"
                  enterTo="opacity-100 scale-100"
                  leave="duration-100"
                  leaveFrom="opacity-50 scale-50"
                  leaveTo="opacity-0 scale-0"
                >
                  <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                    <Dialog.Title className="text-primary text-sm">
                      You have unsaved changes.
                      <br />
                      Are you sure to skip to result?
                    </Dialog.Title>

                    <div className="mt-6 self-stretch flex items-stretch justify-between space-x-6 text-xs">
                      <button
                        className="flex-1 px-4 py-2 border border-secondary rounded-full bg-white text-primary"
                        type="button"
                        onClick={() =>
                          navigate("/scan-result", { replace: true, state: { result: location?.state?.result ?? {} } })
                        }
                      >
                        Skip to Result
                      </button>
                      <button
                        className="flex-1 px-4 py-2 border border-secondary rounded-full bg-secondary text-white"
                        type="button"
                        onClick={() => setModalOpen(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </Transition.Child>
            </Dialog>
          </Transition>
        </>
      )}
    </section>
  ) : (
    <Navigate to="/" replace />
  );
};

export default Survey;
