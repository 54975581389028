import { Fragment, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

import TextInput from "../components/TextInput";
import GenderSelect from "../components/GenderSelect";
import DOBSelect from "../components/DOBSelect";

import { ReactComponent as Loader } from "../assets/icons/Loader.svg";

const BasicDetails = () => {
  const { getToken, logout } = useAuth();

  const [isModalOpen, setModalOpen] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const fnameRef = useRef(null);
  const lnameRef = useRef(null);
  const emailRef = useRef(null);
  const heightRef = useRef(null);
  const weightRef = useRef(null);

  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDOB] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");

  const [fnameError, setFNameError] = useState("");
  const [lnameError, setLNameError] = useState("");
  const [emailExistsList, setEmailExistsList] = useState(new Set());
  const [emailError, setEmailError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [dobError, setDOBError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [weightError, setWeightError] = useState("");

  const [isAccepted, setAccepted] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const proceed = () => {
    try {
      setFNameError("");
      setLNameError("");
      setEmailError("");
      let error = "";
      const trim_email = email.toLowerCase().trim();
      if (trim_email.length > 0 && !/^[a-z0-9]+([._-]*[a-z0-9])+[@](\w+[.])+\w+$/.test(trim_email)) {
        setEmailError("Invalid email address. Please enter a correct email address and try again.");
        error = "email";
      } else if (emailExistsList.has(email)) {
        setEmailError("This Email is already used.");
        error = "email";
      }
      setEmail(trim_email);
      const trim_lname = lname.trim();
      if (!/^[a-zA-Z][\w.'’-]*( [\w.'’-]+)?$/.test(trim_lname)) {
        if (trim_lname.length > 0) {
          setLNameError("No special characters are allowed.");
        } else {
          setLName("");
          setLNameError("Please enter your Last Name.");
        }
        error = "lname";
      }
      setLName(trim_lname);
      const trim_fname = fname.trim();
      if (!/^[a-zA-Z][\w.'’-]*( [\w.'’-]+)?$/.test(trim_fname)) {
        if (trim_fname.length > 0) {
          setFNameError("No special characters are allowed.");
        } else {
          setFName("");
          setFNameError("Please enter your First Name.");
        }
        error = "fname";
      }
      setFName(trim_fname);
      if (error.length > 0) {
        switch (error) {
          case "fname":
            fnameRef.current?.focus?.();
            break;
          case "lname":
            lnameRef.current?.focus?.();
            break;
          case "email":
            emailRef.current?.focus?.();
            break;
          default:
            break;
        }
      } else setPageNo(2);
    } catch (err) {
      console.error(err);
    }
  };

  const saveDetails = async () => {
    try {
      setLoading(true);
      setGenderError("");
      setDOBError("");
      setHeightError("");
      setWeightError("");
      let error = "";
      const trim_weight = weight.trim();
      if (/^\d{1,3}(\.\d{1,2})?$/.test(trim_weight)) {
        if (trim_weight < 20 || trim_weight > 220) {
          setWeightError("Weight must be between 20 and 220 kgs.");
          error = "weight";
        }
      } else {
        setWeightError("Please enter valid Weight.");
        error = "weight";
      }
      setWeight(trim_weight);
      const trim_height = height.trim();
      if (/^\d{1,3}(\.\d{1,2})?$/.test(trim_height)) {
        if (trim_height < 30 || trim_height > 250) {
          setHeightError("Height must be between 30 and 250 cms.");
          error = "height";
        }
      } else {
        setHeightError("Please enter valid Height.");
        error = "height";
      }
      setHeight(trim_height);
      if (dob.length === 0) {
        setDOBError("Please select your Date of Birth.");
        error = "dob";
      }
      if (gender.length === 0) {
        setGenderError("Please select your Gender.");
        error = "gender";
      }
      if (error.length > 0) {
        switch (error) {
          case "height":
            heightRef.current?.focus?.();
            break;
          case "weight":
            weightRef.current?.focus?.();
            break;
          default:
            break;
        }
      } else if (!isAccepted) {
        setModalOpen(true);
      } else {
        const token = await getToken();
        const saveResp = await fetch(sdkWrapperURL("/users/profile/update"), {
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            first_name: fname,
            last_name: lname,
            dob: dob,
            is_terms_accepted: isAccepted,
            weight: weight,
            height: height,
            gender: gender,
            email: email,
          }),
        });
        const saveRespJSON = await saveResp.json();
        if (saveRespJSON?.statusCode?.toString().startsWith("2")) window.location.replace("/");
        else if (saveRespJSON?.message === "Email already exist in our database") {
          setEmailExistsList((emailList) => new Set(emailList.add(email)));
          setEmailError("This Email is already used.");
          setPageNo(1);
        } else throw new Error(saveRespJSON?.message ?? "Error in Saving");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="min-h-screen max-w-sm mx-auto p-6 relative bg-white">
      <h2 className="mt-6 mb-4 text-secondary text-sm font-semibold">
        {pageNo === 1 ? "We need few details," : "Almost done,"}
      </h2>

      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (!isLoading) {
            if (pageNo === 1) proceed();
            else await saveDetails();
          }
        }}
        noValidate
      >
        {pageNo === 1 ? (
          <div className="space-y-4">
            <TextInput
              inputRef={fnameRef}
              type="text"
              id="fname"
              label="First Name"
              placeholder="Enter your First Name"
              autoComplete="given-name"
              required
              maxLength={25}
              onChangeText={setFName}
              value={fname}
              error={fnameError}
            />
            <TextInput
              inputRef={lnameRef}
              type="text"
              id="lname"
              label="Last Name"
              placeholder="Enter your Last Name"
              autoComplete="family-name"
              required
              maxLength={25}
              onChangeText={setLName}
              value={lname}
              error={lnameError}
            />
            <TextInput
              inputRef={emailRef}
              type="email"
              id="email"
              label="Email"
              placeholder="Enter your Email"
              autoComplete="email"
              inputMode="email"
              onChangeText={setEmail}
              value={email}
              error={emailError}
            />
          </div>
        ) : (
          <div className="space-y-4">
            <GenderSelect
              label="Gender"
              required
              onSelect={(selectedGender) => {
                setGenderError("");
                setGender(selectedGender);
              }}
              value={gender}
              error={genderError}
            />
            <DOBSelect
              id="dob"
              label="Date of Birth"
              placeholder="Select your Date of Birth"
              required
              onSelect={setDOB}
              value={dob}
              error={dobError}
            />
            <TextInput
              inputRef={heightRef}
              type="text"
              id="height"
              label="Height (cms)"
              placeholder="Enter your Height in cms"
              inputMode="decimal"
              required
              onChangeText={setHeight}
              value={height}
              error={heightError}
            />
            <TextInput
              inputRef={weightRef}
              type="text"
              id="weight"
              label="Weight (kgs)"
              placeholder="Enter your Weight in kgs"
              inputMode="decimal"
              required
              onChangeText={setWeight}
              value={weight}
              error={weightError}
            />
            <div className="flex items-center px-1 space-x-2">
              <input
                type="checkbox"
                name="terms-n-policy"
                id="terms-n-policy"
                required
                value={isAccepted}
                checked={isAccepted}
                onChange={(e) => setAccepted(e.target.checked)}
              />
              <label htmlFor="terms-n-policy" className="text-lightgray text-xxs">
                I accept the&nbsp;
                <Link
                  className={`text-primary ${isLoading ? "pointer-events-none" : ""}`}
                  to="/terms-of-use"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms of use
                </Link>
                &nbsp;&amp;&nbsp;
                <Link
                  className={`text-primary ${isLoading ? "pointer-events-none" : ""}`}
                  to="/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy
                </Link>
              </label>
            </div>
          </div>
        )}

        <button
          className="mt-8 w-full flex items-center justify-center space-x-2 rounded-full px-4 py-2 bg-secondary disabled:bg-darkgray text-white"
          type="submit"
          disabled={isLoading}
        >
          <span className="text-sm font-medium">{pageNo === 1 ? "Next" : "Proceed"}</span>
          {isLoading && <Loader className="flex-shrink-0 h-4 w-4" />}
        </button>

        <button
          className="block mx-auto mt-4 text-primary text-xs text-center font-medium"
          type="button"
          onClick={pageNo === 1 ? logout : () => setPageNo(1)}
          disabled={isLoading}
        >
          {pageNo === 1 ? "Logout" : "Back"}
        </button>
      </form>
      <Transition show={isModalOpen} as={Fragment}>
        <Dialog as={Fragment} onClose={() => setModalOpen(false)}>
          <Transition.Child
            className="fixed top-0 bottom-0 left-0 right-0 bg-black/70 flex items-center justify-center"
            enter="duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="duration-300 delay-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Transition.Child
              as={Fragment}
              enter="delay-150 duration-200"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="duration-100"
              leaveFrom="opacity-50 scale-50"
              leaveTo="opacity-0 scale-0"
            >
              <Dialog.Panel className="w-4/5 bg-white rounded-2xl p-6 flex flex-col items-center text-center">
                <Dialog.Title className="mt-2 text-primary/70 text-sm">
                  Please accept the Terms & Conditions to proceed further.
                </Dialog.Title>
                <button
                  className="mt-4 px-4 py-2 rounded-full bg-secondary text-white text-xs font-medium"
                  type="button"
                  onClick={() => setModalOpen(false)}
                >
                  Close
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </Transition.Child>
        </Dialog>
      </Transition>
    </section>
  );
};

export default BasicDetails;
