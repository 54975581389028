const TextInput = ({
  inputRef,
  id = "",
  label = "",
  placeholder = "",
  required = false,
  onChangeText = (text = "") => {},
  value = "",
  error = "",
  ...props
}) => (
  <div>
    <label htmlFor={id} className="text-primary/60 text-xs">
      {label}
      {required && <span className="text-error">&nbsp;*</span>}
    </label>
    <input
      ref={inputRef}
      id={id}
      name={id}
      className={`block w-full mt-2 px-4 py-2 rounded-lg border ${
        error?.length > 0 ? "border-error bg-error/5" : "border-lightgray/20"
      } outline-none focus:border-secondary text-primary text-xs`}
      placeholder={placeholder}
      required={required}
      onChange={(e) => onChangeText?.(e.target.value)}
      value={value?.toString?.() ?? ""}
      {...props}
    />
    {error?.length > 0 && <p className="mt-1.5 text-xxs text-error">{error}</p>}
  </div>
);

export default TextInput;
