export const api_key = "CNCPdlz8P5F3c3l45Bxh";
export const sdkWrapperURL = (url = "") =>
  new URL(url, "https://retail-sdk-wrapper.uae.careplix.com");
export const sdkURL = (url = "") =>
  new URL(url, "https://sdk.uae.careplix.com");
export const acceptList = new Set([
  "9051024866", // Arnab
  "8017029639", // Pathikrit
  "7003039310", // Ankit
  "9836374292", // Ankit 2
  "7980499854", // Subho da
  "8777745116", // Asish
  "9153055388", // Subrata
  "9830374972", // Mushkan
  "8509832810", // Aditi
  "7549109011", // Aditi 2
  "9433106106", //manosij da
]);
