import { Link, Navigate, useLocation } from "react-router-dom";

import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as Arrow } from "../assets/icons/Arrow.svg";
import InstructionFaceScan from "../assets/images/InstructionFaceScan.png";
import InstructionFingerScan from "../assets/images/InstructionFingerScan.png";

const ScanInstructions = () => {
  const location = useLocation();
  const scanType = location.state?.scanType;

  return scanType === "face" || scanType === "finger" ? (
    <section className="px-6 py-2">
      <div className="flex items-center text-primary">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Instructions</h3>
      </div>

      <div className="my-6 mx-auto w-48">
        <img
          className="h-36 w-36 mx-auto"
          src={scanType === "face" ? InstructionFaceScan : InstructionFingerScan}
          alt="instructions"
        />
        <h3 className="mt-4 text-primary text-sm font-medium capitalize">{scanType} Scan</h3>
        <ol className="mt-2 ml-2.5 list-outside list-decimal space-y-1.5">
          {(scanType === "face"
            ? [
                "The ambient light should be bright and consistent and must be white.",
                "Remove your masks, eye-wear, or clothing obstruction the face for the duration of scan.",
                "Keep your face and phone steady to avoid movements during the scan.",
                "For light mode: Make sure your face is mostly covered by the blue circle.",
                "If prompted, allow the camera permission.",
              ]
            : [
                "Place your finger on the rear primary camera and the flashlight.",
                "Make sure the screen looks bright red in color.",
                "If the flash is not turned on then place some light behind the phone.",
                "If prompted, allow the camera permission.",
              ]
          ).map((listItem, index) => (
            <li key={index} className="list-item text-darkgray text-xxs">
              {listItem}
            </li>
          ))}
        </ol>
        <Link
          className="max-w-max mt-6 mx-auto flex items-center space-x-2 rounded-full px-4 py-2 bg-secondary text-white"
          to="/select-posture"
          state={{ scanType }}
        >
          <span className="flex-shrink-0 text-xs font-medium">Proceed</span>
          <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
        </Link>
      </div>

      <div className="mt-12 mb-6 p-6 rounded-xl bg-secondary text-white space-y-1.5">
        <h3 className="text-xs font-medium">Disclaimer</h3>
        <p className="text-xxs text-justify">
          For Investigational Use Only. Careplix is not a substitute for the clinical judgment of a healthcare
          professional. Careplix is intended to improve your awareness of general wellness. Careplix does not diagnose,
          treat, mitigate or prevent any disease, symptom, disorder or abnormal physical state. Consult with a health
          care professional or emergency services if you believe you may have a medical issue.
        </p>
      </div>
    </section>
  ) : (
    <Navigate to="/select-option" replace />
  );
};

export default ScanInstructions;
