import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";
import { constants } from "../utils/ranges";

import LoadingScreen from "../components/LoadingScreen";
import ProgressCircle from "../components/ProgressCircle";

import BannerWoman from "../assets/images/BannerWoman.png";
import { ReactComponent as Arrow } from "../assets/icons/Arrow.svg";
import { ReactComponent as Chevron } from "../assets/icons/Chevron.svg";
import { ReactComponent as HeartHealth } from "../assets/icons/HeartHealth.svg";
import { ReactComponent as BodyVitals } from "../assets/icons/BodyVitals.svg";
import { ReactComponent as PhysioFitness } from "../assets/icons/PhysioFitness.svg";
import { ReactComponent as StressIndex } from "../assets/icons/StressIndex.svg";

const Dashboard = () => {
  const {
    getToken,
    userData: { employee_id },
  } = useAuth();
  const [loading, setLoading] = useState(true);
  const [lastScanResult, setLastResult] = useState({});
  const selectedScoreRange = useMemo(
    () =>
      constants["Wellbeing Score"].ranges.reduce((p, c) =>
        lastScanResult.wellness_score > c.point ? c : p
      ),
    [lastScanResult.wellness_score]
  );

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    (async () => {
      try {
        const token = await getToken();
        const scoreResp = await fetch(
          sdkWrapperURL("/users/sdk/test_api_private"),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify({
              action: "list_scan_UL",
              employee_id,
              table_config_rows_per_page: 1,
              table_config_page_no: 1,
            }),
            signal: controller.signal,
          }
        );
        const scoreRespJSON = await scoreResp.json();
        if (
          scoreRespJSON?.statusCode?.toString().startsWith("2") &&
          scoreRespJSON?.result?.length > 0
        )
          setLastResult(scoreRespJSON.result[0]);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <LoadingScreen style={{ minHeight: "60vh" }} />
  ) : (
    <section className="px-6 py-2">
      <div className="px-6 flex items-center space-x-6">
        <ProgressCircle
          loading={loading}
          value={lastScanResult.wellness_score}
          color={selectedScoreRange.color}
          subtitle={selectedScoreRange.label}
        />
        <div>
          <p className="text-primary text-sm font-semibold">Wellbeing Score</p>
          <p className="text-primary/70 text-xxs whitespace-pre-line">
            {lastScanResult.created_at?.length > 0
              ? `Last updated\n${new Date(
                  lastScanResult.created_at
                ).toLocaleDateString(undefined, {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
                })}`
              : "No scan data avaiable"}
          </p>
        </div>
      </div>

      <Link
        to="/select-option"
        className="my-6 bg-gradient-to-r from-secondary to-primary text-white rounded-xl flex items-center justify-between"
      >
        <img
          className="self-end flex-shrink-0 h-20"
          src={BannerWoman}
          alt="Banner"
        />
        <div className="mx-1.5 space-y-1">
          <h3 className="text-xs font-semibold">Know Your Health</h3>
          <p className="text-xxs tracking-tighter leading-tight">
            Do a self-health check in less than one minute.
          </p>
        </div>
        <div className="flex-shrink-0 flex items-center space-x-1 rounded-full mr-3 px-2 py-1 bg-white text-primary">
          <span className="flex-shrink-0 text-xxs tracking-tighter font-medium">
            Start A Scan
          </span>
          <Arrow className="flex-shrink-0 h-2.5 w-2.5" />
        </div>
      </Link>

      {lastScanResult.created_at?.length > 0 && (
        <Link
          to="/scan-result"
          state={{
            result: {
              scan_id: lastScanResult.scan_id,
              posture: lastScanResult.posture,
              wellness_score: lastScanResult.wellness_score,
              vitals: lastScanResult.vitals,
              metadata: {
                heart_scores: lastScanResult.heart_scores,
                cardiovascular: lastScanResult.cardiovascular,
                physiological_scores: lastScanResult.physiological_scores,
                glucose_info: lastScanResult.glucose_info,
              },
            },
          }}
          className="flex items-center justify-between py-4 px-6 rounded-xl bg-[#2B2B2B] text-white"
        >
          <span className="text-sm">View Last Assessment Result</span>
          <Chevron className="flex-shrink-0 h-4 w-4 -rotate-90" />
        </Link>
      )}

      <div className="my-6">
        <h2 className="text-primary text-sm font-medium">My Activities</h2>
        <div className="mt-4 grid grid-cols-4">
          {[
            {
              icon: (
                <HeartHealth
                  style={{
                    color: !(lastScanResult?.created_at?.length > 0)
                      ? "#e7e7e7"
                      : "#C7EF73",
                  }}
                />
              ),
              text: "Heart Health",
              to: "/history/heart-health",
              data: {
                created_at: lastScanResult.created_at,
                sdnn: lastScanResult.heart_scores?.sdnn ?? "--",
                cardiac_out: lastScanResult.cardiovascular?.cardiac_out ?? "--",
                map: lastScanResult.cardiovascular?.map ?? "--",
                vo2max: lastScanResult.physiological_scores?.vo2max ?? "--",
              },
            },
            {
              icon: (
                <BodyVitals
                  style={{
                    color: !(lastScanResult?.created_at?.length > 0)
                      ? "#e7e7e7"
                      : "#DBAFF5",
                  }}
                />
              ),
              text: "Body Vitals",
              to: "/history/body-vitals",
              data: {
                created_at: lastScanResult.created_at,
                heart_rate: lastScanResult.vitals?.heart_rate ?? "--",
                bp: `${lastScanResult.vitals?.bp_sys ?? "--"}/${
                  lastScanResult.vitals?.bp_dia ?? "--"
                }`,
                resp_rate: lastScanResult.vitals?.resp_rate ?? "--",
                oxy_sat_prcnt: lastScanResult.vitals?.oxy_sat_prcnt ?? "--",
              },
            },
            {
              icon: (
                <PhysioFitness
                  style={{
                    color: !(lastScanResult?.created_at?.length > 0)
                      ? "#e7e7e7"
                      : "#9CCFF4",
                  }}
                />
              ),
              text: "Physio Fitness",
              to: "/history/physio-fitness",
              data: {
                bmi: lastScanResult.physiological_scores?.bmi ?? 0,
                tbw: lastScanResult.physiological_scores?.tbw ?? "--",
                tbwp: lastScanResult.physiological_scores?.tbwp ?? "--",
                bloodvolume:
                  lastScanResult.physiological_scores?.bloodvolume ?? "--",
                bodyfat: lastScanResult.physiological_scores?.bodyfat ?? "--",
                cal_fat: lastScanResult.physiological_scores?.cal_fat ?? "--",
                cal_carb: lastScanResult.physiological_scores?.cal_carb ?? "--",
              },
            },
            {
              icon: (
                <StressIndex
                  style={{
                    color: !(lastScanResult?.created_at?.length > 0)
                      ? "#e7e7e7"
                      : "#F8B2B2",
                  }}
                />
              ),
              text: "Stress Index",
              to: "/history/stress-index",
              data: {
                stress_index: lastScanResult.heart_scores?.stress_index ?? 0,
                rmssd: lastScanResult.heart_scores?.rmssd ?? "--",
                heart_rate: lastScanResult.vitals?.heart_rate ?? "--",
                bp_sys: lastScanResult.vitals?.bp_sys ?? "--",
                bp_dia: lastScanResult.vitals?.bp_dia ?? "--",
              },
            },
          ].map((activity, index) => (
            <Link
              key={`activity-${index}`}
              className={`flex flex-col items-center space-y-1.5 ${
                !(lastScanResult?.created_at?.length > 0)
                  ? "cursor-not-allowed"
                  : ""
              }`}
              to={
                !(lastScanResult?.created_at?.length > 0)
                  ? undefined
                  : activity.to
              }
              state={
                !(lastScanResult?.created_at?.length > 0)
                  ? undefined
                  : activity.data
              }
            >
              {activity.icon}
              <h6 className="text-primary text-center text-xs tracking-tight whitespace-nowrap">
                {activity.text}
              </h6>
            </Link>
          ))}
        </div>
      </div>

      <div className="my-8">
        <h2 className="mb-4 text-primary text-sm font-semibold">
          About CarePlix
        </h2>
        <iframe
          className="rounded-xl w-full aspect-video bg-black"
          title="Product Video"
          src="https://www.youtube.com/embed/1cbEVfUtyLw"
        />
      </div>

      <div className="my-8">
        <h2 className="mb-4 text-primary text-sm font-semibold">
          About CarePlix Scan
        </h2>
        <p className="text-darkgray text-xs text-justify">
          Our Digital Health Scan is like having a personal health check-up at
          your fingertips! With just a quick scan, you can monitor important
          vitals like heart rate, blood pressure, and more. Our Vitals
          Monitoring feature makes it easy to stay on top of your well-being, so
          you can feel confident and in control every step of the way.
        </p>
      </div>
    </section>
  );
};

export default Dashboard;
