import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import LoadingScreen from "../../components/LoadingScreen";

import { useAuth } from "../../utils/auth";
import { sdkWrapperURL } from "../../utils/api-url-list";

import { ReactComponent as BackArrow } from "../../assets/icons/BackArrow.svg";
import { ReactComponent as PostureResting } from "../../assets/icons/PostureResting.svg";
import { ReactComponent as PostureStanding } from "../../assets/icons/PostureStanding.svg";
import { ReactComponent as PostureWalking } from "../../assets/icons/PostureWalking.svg";
import { ReactComponent as PostureExercising } from "../../assets/icons/PostureExercising.svg";

const DetailHistory = () => {
  const location = useLocation();
  const { title, keys, unit, ranges } = location.state;
  const {
    getToken,
    userData: { employee_id },
  } = useAuth();
  const noOfItems = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    (async () => {
      try {
        setLoading(true);
        const token = await getToken();
        const resp = await fetch(sdkWrapperURL("/users/sdk/test_api_private"), {
          signal: controller.signal,
          method: "POST",
          headers: { "Content-Type": "application/json", Authorization: token },
          body: JSON.stringify({
            action: "list_scan_UL",
            employee_id,
            table_config_rows_per_page: noOfItems,
            table_config_page_no: currentPage,
          }),
        });
        const respJSON = await resp.json();
        if (respJSON?.statusCode?.toString().startsWith("2")) {
          if (respJSON?.result?.length > 0) {
            setHistory(
              respJSON.result
                .map((res) => ({
                  id: res.scan_id,
                  time: res.created_at,
                  posture: res.posture,
                  value:
                    res[keys[0]][keys[1]] !== "--"
                      ? title === "HRV (SDNN)" || title === "HRV (RMSSD)"
                        ? Math.round(`${res[keys[0]][keys[1]]}`)
                        : title === "Blood Pressure"
                        ? `${res[keys[0]][keys[1]]}/${res[keys[2]][keys[3]]}`
                        : `${res[keys[0]][keys[1]]}`
                      : "--",
                  selectedRange:
                    res[keys[0]][keys[1]] !== "--"
                      ? title === "Heart Rate"
                        ? ranges?.[res.posture ?? "resting"]?.reduce((p, c) =>
                            res[keys[0]][keys[1]] >= c.point ? c : p
                          )
                        : title === "HRV (SDNN)" || title === "HRV (RMSSD)"
                        ? ranges?.reduce((p, c) => (Math.round(`${res[keys[0]][keys[1]]}`) >= c.point ? c : p))
                        : title === "Blood Pressure"
                        ? ranges?.reduce((p, c) => (res[keys[0]][keys[1]] > c.point ? c : p))
                        : ranges?.reduce((p, c) => (res[keys[0]][keys[1]] >= c.point ? c : p))
                        : undefined,
                  }))
                  .filter((res) => res.value !== "--")
              );
              setLastPage(respJSON?.total_pages_count > 1 ? respJSON.total_pages_count : 1);
            } else
            setCurrentPage((p) => {
              const page = p > 1 ? p - 1 : 1;
              setLastPage(page);
              return page;
            });
        } else throw new Error(respJSON?.message ?? "Couldn't get Result.");
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
    return () => {
      controller.abort();
      setLoading(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <section className="px-6 py-2">
      <div className="flex items-center text-primary">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">{title}</h3>
      </div>

      {loading ? (
        <LoadingScreen style={{ minHeight: "50vh" }} />
      ) : history?.length > 0 ? (
        <div className="my-6">
          {history.map((historyItem) => (
            <div className="mb-4 p-4 flex items-center space-x-4 rounded-2xl bg-white shadow-box">
              <div className="flex-shrink-0 p-2 rounded bg-secondary/10 text-primary">
                {historyItem.posture === "exercising" ? (
                  <PostureExercising className="h-6 w-6" />
                ) : historyItem.posture === "walking" ? (
                  <PostureWalking className="h-6 w-6" />
                ) : historyItem.posture === "standing" ? (
                  <PostureStanding className="h-6 w-6" />
                ) : (
                  <PostureResting className="h-6 w-6" />
                )}
              </div>
              <div className="flex-grow space-y-1.5">
                <h4 className="text-primary text-xs font-medium capitalize">{historyItem.posture}</h4>
                <h5 className="text-primary/60 text-xxs">
                  {new Date(historyItem.time).toLocaleString(undefined, {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: true,
                  })}
                </h5>
              </div>
              <div className="text-right font-semibold">
                {historyItem.selectedRange?.label?.length > 0 ? (
                  <>
                    <div>
                      <h4 className="inline text-secondary text-xl">{historyItem.value}</h4>
                      &nbsp;
                      <h5 className="inline text-primary/70 text-xxs">{unit}</h5>
                    </div>
                    <h6 className="text-xxs font-medium" style={{ color: historyItem.selectedRange.color }}>
                      {historyItem.selectedRange.label}
                    </h6>
                  </>
                ) : (
                  <>
                    <h4 className="text-secondary text-xl">{historyItem.value}</h4>
                    <h5 className="text-primary/70 text-xxs">{unit}</h5>
                  </>
                )}
              </div>
            </div>
          ))}
          <div className="mt-6 flex items-center max-w-max mx-auto text-primary text-xs">
            <button
              type="button"
              disabled={loading || currentPage === 1}
              onClick={() => setCurrentPage((p) => p - 1)}
              className="disabled:text-lightgray"
            >
              &lt;&nbsp;Previous
            </button>
            <span className="mx-2 px-4 py-0.5 border-x border-primary text-secondary font-medium">
              Page&nbsp;{currentPage}
            </span>
            <button
              type="button"
              disabled={loading || currentPage === lastPage}
              onClick={() => setCurrentPage((p) => p + 1)}
              className="disabled:text-lightgray"
            >
              Next&nbsp;&gt;
            </button>
          </div>
        </div>
      ) : (
        <p className="my-6 p-4 rounded-2xl bg-white shadow-box text-primary/60 text-xs">No Data to show...</p>
      )}
    </section>
  );
};

export default DetailHistory;
