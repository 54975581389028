import { useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../utils/auth";
import { sdkWrapperURL } from "../utils/api-url-list";

import { ReactComponent as Loader } from "../assets/icons/Loader.svg";
import { ReactComponent as BackArrow } from "../assets/icons/BackArrow.svg";
import { ReactComponent as SelectPostureBanner } from "../assets/icons/SelectPosture.svg";
import { ReactComponent as PostureResting } from "../assets/icons/PostureResting.svg";
import { ReactComponent as PostureStanding } from "../assets/icons/PostureStanding.svg";
import { ReactComponent as PostureWalking } from "../assets/icons/PostureWalking.svg";
import { ReactComponent as PostureExercising } from "../assets/icons/PostureExercising.svg";

const SelectPosture = () => {
  const {
    getToken,
    userData: { employee_id },
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const scanType = location.state?.scanType;
  const [loading, setLoading] = useState(false);
  const [selectedPosture, setPosture] = useState("resting");

  const startScan = async () => {
    try {
      setLoading(true);
      const token = await getToken();
      const scanTokenResp = await fetch(sdkWrapperURL("/users/sdk/test_api_private"), {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({ action: "create_scan_UL", employee_id }),
      });
      const scanTokenRespJSON = await scanTokenResp.json();
      if (scanTokenRespJSON?.statusCode?.toString().startsWith("2")) {
        navigate(`/${scanType}-scan`, {
          state: { scanToken: scanTokenRespJSON.scan_token, posture: selectedPosture },
        });
      } else {
        throw new Error(scanTokenRespJSON?.message ?? "Error in Fetching the Scan Token");
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return scanType === "face" || scanType === "finger" ? (
    <section className="px-6 py-2">
      <div className="flex items-center text-primary">
        <Link className="flex-shrink-0 p-1 pr-2.5" to={-1}>
          <BackArrow className="h-2.5 w-2.5" />
        </Link>
        <h3 className="text-sm font-medium">Select Posture</h3>
      </div>

      <div className="my-6">
        <SelectPostureBanner className="h-36 mx-auto" />
        <h5 className="mt-8 text-primary text-xs text-justify font-semibold">
          Please select the posture you were in immediately prior to the assessment:
        </h5>
        <div className="mt-4 space-y-2">
          {[
            {
              icon: <PostureResting className="flex-shrink-0 h-6 w-6 text-darkgray" />,
              label: "Resting",
              value: "resting",
            },
            {
              icon: <PostureStanding className="flex-shrink-0 h-6 w-6 text-darkgray" />,
              label: "Standing",
              value: "standing",
            },
            {
              icon: <PostureWalking className="flex-shrink-0 h-6 w-6 text-darkgray" />,
              label: "After Walking",
              value: "walking",
            },
            {
              icon: <PostureExercising className="flex-shrink-0 h-6 w-6 text-darkgray" />,
              label: "After Exercising",
              value: "exercising",
            },
          ].map((posture) => (
            <button
              key={`posture-${posture.value}`}
              className={`w-full flex items-center justify-between space-x-4 rounded-xl px-4 py-2 ${
                selectedPosture === posture.value ? "bg-secondary/10" : "bg-white"
              } text-left`}
              onClick={() => setPosture(posture.value)}
              disabled={loading}
            >
              {posture.icon}
              <span className="flex-grow text-primary text-xs">{posture.label}</span>
              <div
                className={`flex-shrink-0 relative h-4 w-4 p-[3px] rounded-full border ${
                  loading ? "border-darkgray" : "border-primary"
                } bg-white`}
              >
                <div
                  className={`h-full w-full rounded-full ${
                    selectedPosture === posture.value ? (loading ? "bg-darkgray" : "bg-secondary") : "bg-transparent"
                  }`}
                />
              </div>
            </button>
          ))}
        </div>
        <p className="mt-4 mx-2 text-darkgray text-xxs text-justify leading-tight">
          Please select the option that best describes your posture before the assessment. This information will help us
          to better understand your physical state during the assessment.
        </p>
        <p className="mt-4 p-2 rounded-xl bg-secondary text-white text-xxs text-center">
          Note: Your posture should be at resting while doing the scan.
        </p>
        <button
          className="mt-8 mx-auto w-40 flex items-center justify-center space-x-2 rounded-full px-4 py-2 bg-secondary disabled:bg-darkgray text-white"
          type="button"
          onClick={startScan}
          disabled={loading}
        >
          <span className="text-xs font-medium">Start Scan</span>
          {loading && <Loader className="flex-shrink-0 h-4 w-4" />}
        </button>
      </div>
    </section>
  ) : (
    <Navigate to="/select-option" replace />
  );
};

export default SelectPosture;
