import Datepicker from "react-tailwindcss-datepicker";

import { ReactComponent as CalendarIcon } from "../assets/icons/Calendar.svg";

const DOBSelect = ({
  id = "",
  label = "",
  placeholder = "",
  required = false,
  onSelect = (selectedDate = "") => {},
  value = "",
  error = "",
}) => (
  <div>
    <label htmlFor={id} className="text-primary/60 text-xs">
      {label}
      {required && <span className="text-error">&nbsp;*</span>}
    </label>
    <Datepicker
      inputId={id}
      primaryColor="blue"
      containerClassName={`relative mt-2 px-4 py-2 flex items-center justify-between space-x-2.5 rounded-lg border ${
        error?.length > 0 ? "border-error bg-error/5 text-error" : "border-lightgray/20 text-primary"
      }`}
      inputClassName="flex-grow text-xs bg-transparent outline-none"
      toggleClassName="flex-shrink-0 h-4 w-4 pointer-events-none"
      toggleIcon={() => <CalendarIcon className="h-full w-full" />}
      value={value.length > 0 && { startDate: value, endDate: value }}
      onChange={(date) => onSelect(date.startDate)}
      useRange={false}
      asSingle
      readOnly
      placeholder={placeholder}
      displayFormat="DD/MM/YYYY"
      startFrom={new Date().setFullYear(new Date().getFullYear() - 18)}
      maxDate={new Date().setFullYear(new Date().getFullYear() - 18)}
      minDate={new Date().setFullYear(new Date().getFullYear() - 110)}
    />
    {error?.length > 0 && <p className="mt-1.5 text-xxs text-error">{error}</p>}
  </div>
);

export default DOBSelect;
